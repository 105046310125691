import { render, staticRenderFns } from "./workshopDetail13.vue?vue&type=template&id=359c6c72&scoped=true&"
import script from "./workshopDetail13.vue?vue&type=script&lang=js&"
export * from "./workshopDetail13.vue?vue&type=script&lang=js&"
import style0 from "./workshopDetail13.vue?vue&type=style&index=0&id=359c6c72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359c6c72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCol,VContainer,VContent,VDivider,VImg,VResponsive,VRow})
